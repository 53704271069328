{
  "dateRange": "{startDate} až {endDate}",
  "dateSelected": "Vybráno {date}",
  "finishRangeSelectionPrompt": "Kliknutím dokončíte výběr rozsahu dat",
  "maximumDate": "Poslední dostupné datum",
  "minimumDate": "První dostupné datum",
  "next": "Další",
  "previous": "Předchozí",
  "selectedDateDescription": "Vybrané datum: {date}",
  "selectedRangeDescription": "Vybrané období: {dateRange}",
  "startRangeSelectionPrompt": "Kliknutím zahájíte výběr rozsahu dat",
  "todayDate": "Dnes, {date}",
  "todayDateSelected": "Dnes, vybráno {date}"
}
