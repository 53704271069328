{
  "previous": "Previous",
  "next": "Next",
  "selectedDateDescription": "Selected Date: {date}",
  "selectedRangeDescription": "Selected Range: {dateRange}",
  "todayDate": "Today, {date}",
  "todayDateSelected": "Today, {date} selected",
  "dateSelected": "{date} selected",
  "startRangeSelectionPrompt": "Click to start selecting date range",
  "finishRangeSelectionPrompt": "Click to finish selecting date range",
  "minimumDate": "First available date",
  "maximumDate": "Last available date",
  "dateRange": "{startDate} to {endDate}"
}
