{
  "calendar": "Calendário",
  "day": "dia",
  "dayPeriod": "AM/PM",
  "endDate": "Data final",
  "era": "era",
  "hour": "hora",
  "minute": "minuto",
  "month": "mês",
  "second": "segundo",
  "selectedDateDescription": "Data selecionada: {date}",
  "selectedRangeDescription": "Intervalo selecionado: {startDate} a {endDate}",
  "selectedTimeDescription": "Hora selecionada: {time}",
  "startDate": "Data inicial",
  "timeZoneName": "fuso horário",
  "weekday": "dia da semana",
  "year": "ano"
}
