{
  "calendar": "Kalender",
  "day": "dag",
  "dayPeriod": "AM/PM",
  "endDate": "Slutdato",
  "era": "æra",
  "hour": "time",
  "minute": "minut",
  "month": "måned",
  "second": "sekund",
  "selectedDateDescription": "Valgt dato: {date}",
  "selectedRangeDescription": "Valgt interval: {startDate} til {endDate}",
  "selectedTimeDescription": "Valgt tidspunkt: {time}",
  "startDate": "Startdato",
  "timeZoneName": "tidszone",
  "weekday": "ugedag",
  "year": "år"
}
