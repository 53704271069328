{
  "dateRange": "{startDate} עד {endDate}",
  "dateSelected": "{date} נבחר",
  "finishRangeSelectionPrompt": "חץ כדי לסיים את בחירת טווח התאריכים",
  "maximumDate": "תאריך פנוי אחרון",
  "minimumDate": "תאריך פנוי ראשון",
  "next": "הבא",
  "previous": "הקודם",
  "selectedDateDescription": "תאריך נבחר: {date}",
  "selectedRangeDescription": "טווח נבחר: {dateRange}",
  "startRangeSelectionPrompt": "לחץ כדי להתחיל בבחירת טווח התאריכים",
  "todayDate": "היום, {date}",
  "todayDateSelected": "היום, {date} נבחר"
}
