{
  "dateRange": "{startDate} a {endDate}",
  "dateSelected": "{date} seleccionado",
  "finishRangeSelectionPrompt": "Haga clic para terminar de seleccionar rango de fechas",
  "maximumDate": "Última fecha disponible",
  "minimumDate": "Primera fecha disponible",
  "next": "Siguiente",
  "previous": "Anterior",
  "selectedDateDescription": "Fecha seleccionada: {date}",
  "selectedRangeDescription": "Intervalo seleccionado: {dateRange}",
  "startRangeSelectionPrompt": "Haga clic para comenzar a seleccionar un rango de fechas",
  "todayDate": "Hoy, {date}",
  "todayDateSelected": "Hoy, {date} seleccionado"
}
