{
  "calendar": "日历",
  "day": "日",
  "dayPeriod": "上午/下午",
  "endDate": "结束日期",
  "era": "纪元",
  "hour": "小时",
  "minute": "分钟",
  "month": "月",
  "second": "秒",
  "selectedDateDescription": "选定的日期：{date}",
  "selectedRangeDescription": "选定的范围：{startDate} 至 {endDate}",
  "selectedTimeDescription": "选定的时间：{time}",
  "startDate": "开始日期",
  "timeZoneName": "时区",
  "weekday": "工作日",
  "year": "年"
}
