{
  "dateRange": "{startDate} til {endDate}",
  "dateSelected": "{date} valgt",
  "finishRangeSelectionPrompt": "Klik for at fuldføre valg af datoområde",
  "maximumDate": "Sidste ledige dato",
  "minimumDate": "Første ledige dato",
  "next": "Næste",
  "previous": "Forrige",
  "selectedDateDescription": "Valgt dato: {date}",
  "selectedRangeDescription": "Valgt interval: {dateRange}",
  "startRangeSelectionPrompt": "Klik for at starte valg af datoområde",
  "todayDate": "I dag, {date}",
  "todayDateSelected": "I dag, {date} valgt"
}
