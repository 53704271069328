{
  "dateRange": "{startDate} 至 {endDate}",
  "dateSelected": "已選取 {date}",
  "finishRangeSelectionPrompt": "按一下以完成選取日期範圍",
  "maximumDate": "最後一個可用日期",
  "minimumDate": "第一個可用日期",
  "next": "下一頁",
  "previous": "上一頁",
  "selectedDateDescription": "選定的日期：{date}",
  "selectedRangeDescription": "選定的範圍：{dateRange}",
  "startRangeSelectionPrompt": "按一下以開始選取日期範圍",
  "todayDate": "今天，{date}",
  "todayDateSelected": "已選取今天，{date}"
}
